<template>
  <div class="about-container ab">
    <Header></Header>
    <div class="container">
      <div class="first">
        <h1 class="fs-64">关于我们</h1>
        <p class="fs-20">
          <b class="fs-20">深如美设（上海）供应链科技有限公司</b>
          位于中国上海，是一家专业的货运代理公司，
        </p>
      </div>
      <div class="banner"></div>
      <div class="third ptb-100">
        <div class="df">
          <div><img src="../assets/img/about/a2.jpg" alt="" /></div>
          <div>
            <p class="fs-20">
              深如美设（上海）供应链科技有限公司基于多年的物流行业从业经历，以及对市场的深入研究、分析。
              推出危险品拼箱及进口FOB两大特色物流业务，
              宗旨是为了满足市场现有的服务空缺,
              提供差异化的物流服务理念，定位于精细化物流服务。<br/>
              深如美设（上海）供应链科技有限公司是具有危险品货物EDI申报质资，
              拥有配套的仓储分拨的硬件设施以及代理报关权的综合性物流企业。
            </p>
            <p>
              <el-button class="mt-30"
                ><a href="mailto:12345@163.com" class="color-white">
                  <span>Email</span>
                </a></el-button
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/header";
import Footer from "../components/footer";

export default {
  name: "About",
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss">
.footer::before {
  content: none;
}
</style>
